import { useEffect, useState } from "react";
import "./App.css";
import getCities from "./util/db.js";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import axios from "axios";
import logo from "./Milan_Horizontal_Reverse_Vector.svg";
import leftQuote from "./left-quote.svg";
import rightQuote from "./right-quote.svg";
import stars from "./stars.svg";

function App() {
  const [locations, setLocations] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [currentReview, setCurrentReview] = useState({
    author: "",
    store_name: "",
    text: ""
  });
  let randomNumber = 0;

  useEffect(() => {
    getCities.then(res => {
      setLocations(res);
      randomNumber = getRandomNum(res.length);
      getReviews(res[randomNumber].google_place_id, res[randomNumber].label);
    });
  }, []);

  const getRandomNum = max => {
    return Math.floor(Math.random() * (max - 0 + 1)) + 0;
  };

  const getReviews = (placeId, label) => {
    axios
      .get(
        `https://cors-milanlaser.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=name,review,formatted_address&key=AIzaSyC01tLmFqcLmpsiKXrRgxBUERlUcgiugis`
      )
      .then(res => {
        // console.log(res.data.result);
        let tempArray = [];
        res.data.result.reviews.map(review => {
          if (review.rating === 5) {
            tempArray.push({
              text:
                review.text.length > 400
                  ? review.text.substr(0, 400) + "..."
                  : review.text,
              author: review.author_name,
              store_name: label
            });
          }
        });
        setReviews(tempArray);
        popReiews(tempArray);
      });
  };

  const popReiews = reviewsArray => {
    let index = 0;
    setCurrentReview(reviewsArray[index]);
    setInterval(() => {
      if (index !== reviewsArray.length - 1) {
        index++;
      } else {
        index = 0;
      }
      // console.log(index);
      setCurrentReview(reviewsArray[index]);
    }, 30000);
  };

  return (
    <div className="App">
      <Grid
        container
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "5%"
        }}
      >
        <img src={logo} alt="logo" width="40%" />
        <Grid
          item
          md={12}
          style={{ marginTop: "5%", display: "flex", alignItems: "flex-start" }}
        >
          {currentReview.text.length > 0 ? (
            <>
              <Grid item md={1}>
                <img
                  src={leftQuote}
                  alt="left quote"
                  width="95%"
                  style={{ marginTop: "-2%" }}
                />
              </Grid>

              <Grid
                item
                md={10}
                style={{
                  textAlign: "left",
                  marginLeft: "4%",
                  marginRight: "4%"
                }}
              >
                <img src={stars} alt="stars" width="30%" />
                <Typography
                  variant="h2"
                  style={{
                    color: "white",
                    fontSize: "3.25rem",
                    fontWeight: 500,
                    lineHeight: 1.4
                  }}
                >
                  {currentReview.text}
                </Typography>
                <Typography
                  variant="h1"
                  style={{
                    color: "white",
                    textAlign: "right",
                    fontSize: "4rem",
                    fontWeight: 300,
                    marginTop: 10
                  }}
                >
                  {" "}
                  - {currentReview.author}
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    color: "white",
                    textAlign: "right",
                    fontWeight: 600
                  }}
                >
                  Milan Laser {currentReview.store_name}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <img
                  alt="right"
                  src={rightQuote}
                  width="95%"
                  style={{ marginTop: "57%" }}
                />
              </Grid>
            </>
          ) : (
            <Grid
              item
              md={12}
              style={{
                textAlign: "center",
                justifyContent: "center",
                marginLeft: "4%",
                marginRight: "4%"
              }}
            >
              <CircularProgress style={{ color: "white", marginTop: "35%" }} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
